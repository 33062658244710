<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col sm="6" md="4">
          <p class="headline font-weight-bold">About</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="6" md="4">
          <p class="title">This is an about page</p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "about",
};
</script>
