<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col sm="6" md="4">
          <p class="headline font-weight-bold">Contact</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="6" md="4">
          <p class="subtitle-1">The City of North Battleford</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="6" md="4">
          <p class="subtitle-1 font-weight-bold">City Hall Hours</p>
          <p class="font-regular">
            Monday to Friday from 9:00am&nbsp;until 4:30pm.
          </p>
          <p class="font-regular">
            City Hall is closed on all statutory holidays.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="6" md="4">
          <p class="subtitle-1 font-weight-bold">Address</p>
          <p>
            <span>
              1291-101st Street
              <br />P.O. Box 460
            </span>
            <br />
            <span>North Battleford, SK</span>
            <br />
            <span>S9A 2Y6</span>
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="6" md="4">
          <p class="subtitle-1 font-weight-bold">General Inquiries</p>
          <p>
            <span>
              <a href="tel:3064451700">306-445-1700</a>
            </span>
          </p>
          <p>
            <strong>CSO/Municipal Enforcement phone number</strong>
            <br />
            <a href="tel:3064451775">306-445-1775</a>
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "contact",
};
</script>
