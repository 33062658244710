<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col sm="6" md="4">
          <p class="headline font-weight-bold">Home</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="6" md="4">
          <p class="title">Pay Your Bills and Fines</p>
        </v-col>
      </v-row>
      <v-spacer></v-spacer>
      <v-row>
        <v-col sm="6" md="4">
          <v-img
            src="https://res.cloudinary.com/lexcom/image/upload/c_scale,e_bgremoval,h_300,q_auto:eco/v1596845906/site/home_jppeua.png"
            height="300"
            contain
          ></v-img>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "home",
};
</script>
